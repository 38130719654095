import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { AccountStatus } from '../AccountStatus';
import { checkIsLatamSpecificData } from '../../../../services/schemas/accountsBackendCodec';
import { FormAccount } from '../formAccount.ts';
import { getFeatureFlagForUser } from '../../../../services/FeatureFlagProvider.tsx';

export const ModifiedAccountData = ({
    formAccount,
    dirtyFields,
    oldFormAccount,
}: {
    formAccount: FormAccount;
    dirtyFields?: Partial<Readonly<FieldNamesMarkedBoolean<FormAccount>>>;
    oldFormAccount?: FormAccount;
}) => {
    const isLatamSpecificData = checkIsLatamSpecificData(formAccount.tenantSpecificData);

    const contactFlag = getFeatureFlagForUser('accountcontactshelpgang');

    return (
        <>
            <table className={'table table-head-filled table-condensed'}>
                <thead>
                    <tr>
                        <th>Properties</th>
                        {oldFormAccount ? <th className={'text-color-gray'}>Account before edit</th> : ''}
                        <th>Account after edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Account Id</td>
                        {oldFormAccount ? <td className={'text-color-gray'}>{oldFormAccount.company.id}</td> : ''}
                        <td>{formAccount.company.id}</td>
                    </tr>
                    <tr className={dirtyFields?.company?.name ? 'text-bold text-color-warning' : ''}>
                        <td>Account Name</td>
                        {oldFormAccount ? <td className={'text-color-gray'}>{oldFormAccount.company.name}</td> : ''}
                        <td>{formAccount.company.name}</td>
                    </tr>
                    <tr>
                        <td>Account Tenant</td>
                        {oldFormAccount ? <td className={'text-color-gray'}>{oldFormAccount.company.tenant}</td> : ''}
                        <td>{formAccount.company.tenant}</td>
                    </tr>
                    <tr>
                        <td>Account Type</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.accountType}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.accountType}</td>
                    </tr>
                    <tr>
                        <td>Registration Channel</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.registrationChannel}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.registrationChannel}</td>
                    </tr>
                    <tr className={dirtyFields?.company?.taxId?.value ? 'text-bold text-color-warning' : ''}>
                        <td>Tax Id</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.taxId?.value}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.taxId?.value}</td>
                    </tr>
                    <tr className={dirtyFields?.company?.taxId?.taxType ? 'text-bold text-color-warning' : ''}>
                        <td>Type of Tax Id</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.taxId?.taxType}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.taxId?.taxType}</td>
                    </tr>
                    <tr className={dirtyFields?.company?.dunsNumbers ? 'text-bold text-color-warning' : ''}>
                        <td>DUNS</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>
                                <ul className="icon-list margin-bottom-0">
                                    {oldFormAccount.company.dunsNumbers?.map((it) => (
                                        <li key={it} className={'margin-bottom-0'}>
                                            {it}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        ) : (
                            ''
                        )}
                        <td>
                            <ul className="icon-list margin-bottom-0">
                                {formAccount.company.dunsNumbers?.map((it) => (
                                    <li key={it} className={'margin-bottom-0'}>
                                        {it}
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                    <tr className={dirtyFields?.company?.legalAddress?.line1 ? 'text-bold text-color-warning' : ''}>
                        <td>Address Line 1 (usually street & no.)</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.legalAddress.line1}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.legalAddress.line1}</td>
                    </tr>
                    <tr>
                        <td>Address Line 2 (usually unused)</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.legalAddress.line2}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.legalAddress.line2}</td>
                    </tr>
                    <tr>
                        <td>Address Line 3 (usually unused)</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.legalAddress.line3}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.legalAddress.line3}</td>
                    </tr>
                    <tr className={dirtyFields?.company?.legalAddress?.city ? 'text-bold text-color-warning' : ''}>
                        <td>Town/City</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.legalAddress.city}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.legalAddress.city}</td>
                    </tr>
                    <tr className={dirtyFields?.company?.legalAddress?.postCode ? 'text-bold text-color-warning' : ''}>
                        <td>Post Code</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.legalAddress.postCode}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.legalAddress.postCode}</td>
                    </tr>
                    <tr
                        className={
                            dirtyFields?.company?.legalAddress?.countryCode ? 'text-bold text-color-warning' : ''
                        }
                    >
                        <td>Country Code</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.legalAddress.countryCode}</td>
                        ) : (
                            ''
                        )}
                        <td>{formAccount.company.legalAddress.countryCode}</td>
                    </tr>
                    <tr>
                        <td>Lifecycle State</td>
                        {oldFormAccount ? (
                            <td className={'text-color-gray'}>{oldFormAccount.company.lifeCycleState}</td>
                        ) : (
                            ''
                        )}
                        <td>
                            {dirtyFields ? (
                                formAccount.company.lifeCycleState
                            ) : (
                                <AccountStatus status={formAccount.company.lifeCycleState} />
                            )}
                        </td>
                    </tr>
                    {formAccount.tenantSpecificData && isLatamSpecificData && oldFormAccount?.tenantSpecificData && (
                        <>
                            <tr className={'text-color-dark bg-lightest'} style={{ borderWidth: '10px' }}>
                                <td colSpan={oldFormAccount ? 3 : 2}>Tenant specific data</td>
                            </tr>
                            <tr>
                                <td>City Id</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>{oldFormAccount.tenantSpecificData.cityId}</td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.tenantSpecificData.cityId}</td>
                            </tr>
                            <tr
                                className={
                                    dirtyFields?.tenantSpecificData?.neighbourhood ? 'text-bold text-color-warning' : ''
                                }
                            >
                                <td>Neighborhood</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>
                                        {oldFormAccount.tenantSpecificData.neighbourhood}
                                    </td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.tenantSpecificData.neighbourhood}</td>
                            </tr>
                            <tr>
                                <td>State</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>{oldFormAccount.tenantSpecificData.state}</td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.tenantSpecificData.state}</td>
                            </tr>
                            <tr
                                className={
                                    dirtyFields?.tenantSpecificData?.phoneNumber ? 'text-bold text-color-warning' : ''
                                }
                            >
                                <td>Phone number</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>
                                        {oldFormAccount.tenantSpecificData.phoneNumber}
                                    </td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.tenantSpecificData.phoneNumber}</td>
                            </tr>
                            <tr
                                className={
                                    dirtyFields?.tenantSpecificData?.addressDetails
                                        ? 'text-bold text-color-warning'
                                        : ''
                                }
                            >
                                <td>Address details</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>
                                        {oldFormAccount.tenantSpecificData.addressDetails}
                                    </td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.tenantSpecificData.addressDetails}</td>
                            </tr>
                        </>
                    )}
                    {contactFlag && (
                        <>
                            <tr className={'text-color-dark bg-lightest'} style={{ borderWidth: '10px' }}>
                                <td colSpan={oldFormAccount ? 3 : 2}>Contact</td>
                            </tr>
                            <tr className={dirtyFields?.contact?.general ? 'text-bold text-color-warning' : ''}>
                                <td>General</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>{oldFormAccount.contact.general.email}</td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.contact.general.email}</td>
                            </tr>
                            <tr className={dirtyFields?.contact?.billing ? 'text-bold text-color-warning' : ''}>
                                <td>Billing</td>
                                {oldFormAccount ? (
                                    <td className={'text-color-gray'}>{oldFormAccount.contact.billing.email}</td>
                                ) : (
                                    ''
                                )}
                                <td>{formAccount.contact.billing.email}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </>
    );
};
