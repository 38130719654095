import { FormattedMessage, IntlProvider } from 'react-intl';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import { ClientMetricsPage } from '../components/tabs/client-metrics/ClientMetricsPage';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import { useGetAllClientsByGrantTypeQuery } from '../services/clientRegistryApi';
import { AccountsSidebar } from '../components/tabs/accounts/sidebar/AccountsSidebar';
import { AccountsPage } from '../components/tabs/accounts/AccountsPage';
import { PartnerIntegrationPage } from '../components/tabs/partner-integrations/PartnerIntegrationPage';
import { InboundConnectionsIframe } from '../components/tabs/easy-onboarding-connections/EasyOnboardingIframe';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice.ts';
import { isUserSessionExpired } from '../../configuration/login/loginSlice.ts';
import { DEFAULT_LOCALE } from '../../configuration/lang/lang.ts';
import { IotEventsWrapper } from '../components/tabs/iot-events/IotEventsWrapper.tsx';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice.ts';
import { config } from '../../config.ts';
import { FeatureFlagProvider } from '../services/FeatureFlagProvider.tsx';

type AppProperties = { logout: () => void };

export const App = (_props: AppProperties) => {
    const displayMessages = useAppSelector(getDisplayMessages);
    const userLocale = useAppSelector(getLocale);
    const showSessionExpired = useAppSelector(
        (state) => isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    );

    const dispatch = useAppDispatch();

    // initialize GET for clients here to make data accessible as soon as possible
    useGetAllClientsByGrantTypeQuery('partner_integration');

    if (!displayMessages || !userLocale) {
        return null;
    }

    const idToken = useAppSelector(getIdToken)!;
    const postLogout = config.postLogoutRedirectUri
        ? {
              redirectUri: config.postLogoutRedirectUri,
              idTokenHint: idToken,
          }
        : undefined;

    const userMenu = (
        <DefaultUserMenu environment={import.meta.env.MODE} disableCookieModal={true} postLogout={postLogout} />
    );

    const navItems = [
        {
            key: 'accounts',
            route: (
                <NavLink to={'/accounts'}>
                    <FormattedMessage id={'helpgang.sublink.accounts'} />
                </NavLink>
            ),
        },
        {
            key: 'partner-integrations',
            route: (
                <NavLink to={'/partner-integrations'}>
                    <FormattedMessage id={'helpgang.sublink.partner-integrations'} />
                </NavLink>
            ),
        },
        {
            key: 'client-metrics',
            route: (
                <NavLink to={'/client-metrics'}>
                    <FormattedMessage id={'helpgang.sublink.client-metrics'} />
                </NavLink>
            ),
        },
        {
            key: 'assets',
            route: (
                <a href={'https://helpgang.assets.rio.cloud'}>
                    <FormattedMessage id={'helpgang.sublink.assets'} />
                </a>
            ),
        },
        {
            key: 'device-state',
            route: (
                <a href={'https://corecon-admin-web.vcp-connector.rio.cloud/#/device-state/home'} target={'_blank'}>
                    <FormattedMessage id={'helpgang.sublink.device-state'} />
                </a>
            ),
        },
        {
            key: 'inbound-connections',
            route: (
                <NavLink to={'/inbound-connections'}>
                    <FormattedMessage id={'helpgang.sublink.inbound-connections'} />
                </NavLink>
            ),
        },
        {
            key: 'activation',
            route: (
                <a href={'https://admin.marketplace.rio.cloud/activation'}>
                    <FormattedMessage id={'helpgang.sublink.activation'} />
                </a>
            ),
        },
        {
            key: 'discount',
            route: (
                <a href={'https://admin.marketplace.rio.cloud/discount'}>
                    <FormattedMessage id={'helpgang.sublink.discount'} />
                </a>
            ),
        },
        {
            key: 'iot-events',
            route: (
                <NavLink to={'/iot-events'}>
                    <FormattedMessage id={'helpgang.sublink.iot-events'} />
                </NavLink>
            ),
        },
    ];

    const appTitle = <FormattedMessage id={'helpgang.moduleName'} />;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <FeatureFlagProvider>
                <ApplicationLayout>
                    <ApplicationLayout.Header>
                        <ApplicationHeader label={appTitle} navItems={navItems} actionBarItems={[userMenu]} />
                    </ApplicationLayout.Header>
                    <ApplicationLayout.Sidebar className={'right'}>
                        <AccountsSidebar />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body>
                        <NotificationsContainer />
                        <SessionExpiredDialog
                            locale={userLocale}
                            onClose={() => dispatch(hideSessionExpiredDialog())}
                            show={showSessionExpired}
                        />
                        <Routes>
                            <Route path={'/accounts'} element={<AccountsPage />} />
                            <Route path={'/partner-integrations'} element={<PartnerIntegrationPage />} />
                            <Route path={'/client-metrics'} element={<ClientMetricsPage />} />
                            <Route path={'/inbound-connections'} element={<InboundConnectionsIframe />} />
                            <Route path={'/iot-events'} element={<IotEventsWrapper />} />
                            <Route path={'/'} element={<Navigate replace={true} to="/accounts" />} />
                        </Routes>
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            </FeatureFlagProvider>
        </IntlProvider>
    );
};
