import Select from '@rio-cloud/rio-uikit/Select';
import GenericFormElement from '../sidebar/GenericFormElement.tsx';

export type SelectOptionType = {
    label: string;
    id: string;
    selected?: boolean;
};
export const DropDownFormElement = (props: {
    description?: string;
    name: 'company.taxId.taxType' | 'company.legalAddress.countryCode';
    rules: any;
    options: SelectOptionType[];
    disabled?: boolean;
}) => {
    const getOptions = (selectedValue: string) =>
        props.options.map(
            (item: SelectOptionType): SelectOptionType => ({
                ...item,
                selected: item.id === selectedValue,
            }),
        );
    return (
        <GenericFormElement
            description={props.description}
            name={props.name}
            rules={props.rules}
            renderElement={(field, fieldState) => {
                return (
                    <div className={`form-group ${fieldState.error ? 'has-feedback has-error' : ''}`}>
                        <label className="control-label">{props.description}</label>
                        <Select
                            placeholder="Please select..."
                            options={getOptions(field.value!)} // TODO: field.value is not null
                            onChange={(selectedOption: any) => field.onChange(selectedOption.id)}
                            disabled={props.disabled}
                        />
                    </div>
                );
            }}
        />
    );
};
