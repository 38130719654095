import { config, KnownFeatureFlags } from '../../config.ts';
import {
    ConfigCatProvider,
    createConsoleLogger,
    createFlagOverridesFromMap,
    DataGovernance,
    OverrideBehaviour,
    useFeatureFlag,
    User,
} from 'configcat-react';
import { ReactNode } from 'react';
import { useAppSelector } from '../../configuration/setup/hooks.ts';
import { getIdTokenClaims } from '../../configuration/tokenHandling/tokenSlice.ts';

interface FeatureFlagProviderProps {
    children: ReactNode;
}

export const getFeatureFlagForUser = (key: KnownFeatureFlags) => {
    const idToken = useAppSelector(getIdTokenClaims);
    if (!idToken || !idToken.sub || !idToken.email) {
        return config.configCat.defaults[key];
    }

    const configCatUser = new User(idToken.sub, idToken.email);
    const configCatFeatureFlag = useFeatureFlag(key, false, configCatUser);

    if (configCatFeatureFlag.loading) {
        return config.configCat.defaults[key];
    } else {
        return configCatFeatureFlag.value;
    }
};

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children }) => {
    return (
        <ConfigCatProvider
            sdkKey={config.configCat.apiKey}
            options={{
                flagOverrides: createFlagOverridesFromMap(
                    config.configCat.defaults,
                    import.meta.env.DEV ? OverrideBehaviour.LocalOnly : OverrideBehaviour.RemoteOverLocal,
                ),
                logger: createConsoleLogger(config.configCat.logLevel),
                dataGovernance: DataGovernance.EuOnly,
                pollIntervalSeconds: config.configCat.pollingFrequencyInSeconds,
            }}
        >
            {children}
        </ConfigCatProvider>
    );
};
