import { DropDownFormElement } from '../DropDownFormElement';
import SimpleTextFormElement from '../../sidebar/SimpleTextFormElement';
import { formOptions, getValidTaxTypeOptions } from '../../sidebar/formOptions';
import { useFormContext } from 'react-hook-form';
import { checkIsLatamSpecificData } from '../../../../../services/schemas/accountsBackendCodec';
import DunsListTextAreaFormElement from '../DunsListTextAreaFormElement';
import { FormAccount } from '../../formAccount.ts';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { getFeatureFlagForUser } from '../../../../../services/FeatureFlagProvider.tsx';

export const emailValidatorPattern =
    /^[\x21-\x26\x28-\x7E]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

interface Props {
    onSubmit: (formAccount: FormAccount) => void;
    accountIsInUnexpectedState: boolean;
    accountType: string | undefined;
}

export const EditAccountForm = (props: Props) => {
    const { onSubmit, accountIsInUnexpectedState, accountType } = props;
    const { handleSubmit, formState, getValues } = useFormContext<FormAccount>(); // retrieve all hook methods

    const isRioBrazilTenant = getValues('company.tenant')?.startsWith('rio-brazil.');
    const isRioBrazilSpecificData = checkIsLatamSpecificData(getValues('tenantSpecificData'));
    const isRioBrazilAccountWithMissingData = isRioBrazilTenant && isRioBrazilSpecificData !== true;
    const isCarrier = getValues('company.accountType') === 'CARRIER';

    const contactFlag = getFeatureFlagForUser('accountcontactshelpgang');

    return (
        <div className="margin-25">
            <form onSubmit={handleSubmit((formAccount: FormAccount) => onSubmit(formAccount))}>
                <ExpanderPanel
                    open={true}
                    title="Company Details"
                    bsStyle={formState.errors.company ? 'danger' : 'default'}
                >
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <SimpleTextFormElement
                                name="company.id"
                                description="Account Id"
                                rules={{ required: true }}
                                isEditable={false}
                            />
                            <SimpleTextFormElement
                                name="company.name"
                                description="Account Name*"
                                rules={{ required: true }}
                                isEditable={true}
                            />
                            <SimpleTextFormElement
                                name="company.tenant"
                                description="Account Tenant"
                                rules={{ required: true }}
                                isEditable={false}
                            />
                            <SimpleTextFormElement
                                name="company.registrationChannel"
                                description="Registration Channel"
                                rules={{ required: true }}
                                isEditable={false}
                            />
                            <SimpleTextFormElement
                                name="company.taxId.value"
                                description="Tax Id"
                                rules={{ required: false }}
                                isEditable={true}
                            />
                            <DropDownFormElement
                                name="company.taxId.taxType"
                                description="Type of Tax Id"
                                rules={{ required: false }}
                                options={getValidTaxTypeOptions(getValues('company.tenant'))}
                            />
                            <DunsListTextAreaFormElement
                                name="company.dunsNumbers"
                                description="DUNS"
                                accountType={accountType}
                            />
                        </div>

                        <div className={'col-md-6'}>
                            <SimpleTextFormElement
                                name="company.legalAddress.line1"
                                description="Address Line 1 (usually street & no.)"
                                rules={{ required: false }}
                                isEditable={true}
                            />
                            <SimpleTextFormElement
                                name="company.legalAddress.line2"
                                description="Address Line 2 (usually unused)"
                                rules={{ required: false }}
                                isEditable={false}
                            />
                            <SimpleTextFormElement
                                name="company.legalAddress.line3"
                                description="Address Line 3 (usually unused)"
                                rules={{ required: false }}
                                isEditable={false}
                            />
                            <SimpleTextFormElement
                                name="company.legalAddress.city"
                                description="Town/City"
                                rules={{ required: false }}
                                isEditable={!isRioBrazilSpecificData}
                            />
                            <SimpleTextFormElement
                                name="company.legalAddress.postCode"
                                description="Post Code"
                                rules={{ required: false }}
                                isEditable={true}
                            />
                            <DropDownFormElement
                                name="company.legalAddress.countryCode"
                                description="Country"
                                rules={{ required: true }}
                                options={formOptions}
                                disabled={isRioBrazilSpecificData}
                            />
                            <SimpleTextFormElement
                                name="company.lifeCycleState"
                                description="Lifecycle State"
                                rules={{ required: true }}
                                isEditable={false}
                            />
                        </div>
                    </div>
                </ExpanderPanel>
                {isRioBrazilAccountWithMissingData && (
                    <div className="alert alert-warning">
                        <div className="display-flex gap-10">
                            <span className="text-color-warning text-size-h4 rioglyph rioglyph rioglyph-exclamation-sign"></span>
                            <div>
                                <strong className="text-size-16">Inconsistent account state</strong>
                                <div className="margin-y-5">
                                    Detected a RIO Brazil account with missing tenant specific data. Since the data is
                                    inconsistent it is currently not possible to edit this account here. Please contact
                                    Team Network!
                                </div>
                                <div className="text-medium text-uppercase text-size-12">
                                    <a href="mailto:teamnetwork@rio.cloud" className="alert-link">
                                        teamnetwork@rio.cloud
                                    </a>
                                    <span className="text-color-warning margin-left-3 rioglyph rioglyph-new-window"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isRioBrazilTenant && (
                    <>
                        <ExpanderPanel
                            open={false}
                            title="Tenant Specific Data"
                            bsStyle={formState.errors.tenantSpecificData ? 'danger' : 'default'}
                        >
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <SimpleTextFormElement
                                        name="tenantSpecificData.cityId"
                                        description="City Id"
                                        rules={{ required: isRioBrazilSpecificData }}
                                        isEditable={false}
                                    />
                                    <SimpleTextFormElement
                                        name="tenantSpecificData.neighbourhood"
                                        description="Neighborhood*"
                                        rules={{ required: isRioBrazilSpecificData }}
                                        isEditable={isRioBrazilAccountWithMissingData !== true}
                                    />
                                    <SimpleTextFormElement
                                        name="tenantSpecificData.addressDetails"
                                        description="Address details"
                                        rules={{ required: false }}
                                        isEditable={isRioBrazilAccountWithMissingData !== true}
                                    />
                                </div>
                                <div className={'col-md-6'}>
                                    <SimpleTextFormElement
                                        name="tenantSpecificData.state"
                                        description="State"
                                        rules={{ required: isRioBrazilSpecificData }}
                                        isEditable={false}
                                    />
                                    <SimpleTextFormElement
                                        name="tenantSpecificData.phoneNumber"
                                        description="Phone number*"
                                        rules={{ required: isRioBrazilSpecificData }}
                                        isEditable={isRioBrazilAccountWithMissingData !== true}
                                    />
                                </div>
                            </div>
                        </ExpanderPanel>
                    </>
                )}
                {accountIsInUnexpectedState && (
                    <div className="margin-top-25 alert alert-dismissible alert-danger">
                        <div className="display-flex">
                            <span className="text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-error-sign"></span>
                            <div>
                                <strong className="text-size-16">Invalid state</strong>
                                <div className="margin-y-5">
                                    This account is in an unexpected Lifecycle Cycle State and therefore not editable.
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {contactFlag && (
                    <ExpanderPanel
                        open={false}
                        title="Contact Details"
                        bsStyle={formState.errors.contact ? 'danger' : 'default'}
                    >
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <SimpleTextFormElement
                                    name="contact.general.email"
                                    description="General Email*"
                                    rules={{ required: true, maxLength: 73, pattern: emailValidatorPattern }}
                                    isEditable={true}
                                />
                            </div>
                            <div className={'col-md-6'}>
                                <SimpleTextFormElement
                                    name="contact.billing.email"
                                    description="Billing Email*"
                                    rules={{ required: isCarrier, maxLength: 73, pattern: emailValidatorPattern }}
                                    isEditable={true}
                                />
                            </div>
                        </div>
                    </ExpanderPanel>
                )}

                <div className="margin-top-25 margin-bottom-15 display-flex justify-content-end">
                    <button
                        type={'submit'}
                        className={'btn btn-primary'}
                        disabled={
                            !formState.isDirty ||
                            formState.isValidating ||
                            !formState.isValid ||
                            accountIsInUnexpectedState ||
                            isRioBrazilAccountWithMissingData
                        }
                    >
                        Update Account
                    </button>
                </div>
            </form>
        </div>
    );
};
